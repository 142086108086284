// CSS
import '../sass/main.scss'

// === DYNAMIC SCRIPTS IMPORTER
// note: this file MUST be placed before WP Alpha main, otherwise scripts might load too late
// import './components/dynamic-scripts-importer'

// === WP ALPHA ASSETS
import '@nematis/wp-alpha/assets/js/main'

// import 'swiper/css';
// import 'swiper/css/grid';

// === THIRD PARTY
import './third-party/rousquille'
